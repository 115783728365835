import React from 'react'
import Layout from '../../components/layout'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Seo from '../../utils/seo';
import img1 from "../../images/bunfestival/bunfestival-1.png";
import filter1 from "../../images/bunfestival/filters/1.png";
import filter2 from "../../images/bunfestival/filters/2.png";
import filter3 from "../../images/bunfestival/filters/3.png";
import filter4 from "../../images/bunfestival/filters/4.png";
import filter5 from "../../images/bunfestival/filters/5.png";
import filter6 from "../../images/bunfestival/filters/6.png";
import filter7 from "../../images/bunfestival/filters/7.png";
import filter8 from "../../images/bunfestival/filters/8.png";
import filter9 from "../../images/bunfestival/filters/9.png";
import filter10 from "../../images/bunfestival/filters/10.png";

export default function FilterFB() {
    return (
        <Layout>

                <h2 className='about_slogan'>長洲太平清醮 AR多重宇宙<br></br>AR互動遊戲/濾鏡連結(Facebook版)</h2>
                <img src={img1} alt="Bun Festival" className="about_M2gether_IMG mb-5" />
                <Row>
                    <Col className="text-center" xs={6} md={3}>
                    <a href="https://www.facebook.com/fbcameraeffects/tryit/1220350568625458/" target='blank'>
                    <img src={filter8} alt="Facebook AR Filter" className="about_M2gether_IMG" />
                    <h6>快問快答．睇你了解幾多</h6>
                    <p>點擊開啟</p> <br></br>

                    </a>
                    </Col>
                    <Col className="text-center" xs={6} md={3}>
                    <a href="https://www.facebook.com/fbcameraeffects/tryit/3367690490157359/" target='blank'>
                    <img src={filter7} alt="Facebook AR Filter" className="about_M2gether_IMG" />
                    <h6>張保仔．遙控海盜船</h6>
                    <p>點擊開啟</p> <br></br>
                    </a>
                    </Col>
                    <Col className="text-center" xs={6} md={3}>
                    <a href="https://www.facebook.com/fbcameraeffects/tryit/597343169030246/" target='blank'>
                    <img src={filter4} alt="Facebook AR Filter" className="about_M2gether_IMG" />
                    <h6>震撼鬼王大士王</h6>
                    <p>點擊開啟</p> <br></br>
                    </a>
                    </Col>
                    <Col className="text-center" xs={6} md={3}>
                    <a href="https://www.facebook.com/fbcameraeffects/tryit/6179894352100469/" target='blank'>
                    <img src={filter5} alt="Facebook AR Filter" className="about_M2gether_IMG" />
                    <h6>神明保佑北帝爺</h6>
                    <p>點擊開啟</p> <br></br>
                    </a>
                    </Col>
                    <Col className="text-center" xs={6} md={3}>
                    <a href="https://www.facebook.com/fbcameraeffects/tryit/567262278854838/" target='blank'>
                    <img src={filter3} alt="Facebook AR Filter" className="about_M2gether_IMG" />
                    <h6>長洲特色搶包山</h6>
                    <p>點擊開啟</p> <br></br>
                    </a>
                    </Col>
                    <Col className="text-center" xs={6} md={3}>
                    <a href="https://www.facebook.com/fbcameraeffects/tryit/634795424814465/" target='blank'>
                    <img src={filter6} alt="Facebook AR Filter" className="about_M2gether_IMG" />
                    <h6>平安包．召喚<br></br>（掃描扇上的平安包圖案）</h6>
                    <p>點擊開啟</p> <br></br>
                    </a>
                    </Col>
                    <Col className="text-center" xs={6} md={3}>
                    <a href="https://www.facebook.com/fbcameraeffects/tryit/139203835812201/" target='blank'>
                    <img src={filter1} alt="Facebook AR Filter" className="about_M2gether_IMG" />
                    <h6>傳統閃動勁花牌<br></br>（掃描長洲ARENA[7-11旁]限定花牌。禮品換領點）</h6>
                    <p>點擊開啟</p> <br></br>
                    </a>
                    </Col>
                    <Col className="text-center" xs={6} md={3}>
                    <a href="https://www.facebook.com/fbcameraeffects/tryit/633042525338409/" target='blank'>
                    <img src={filter2} alt="Facebook AR Filter" className="about_M2gether_IMG" />
                    <h6>自拍系列：一齊黎舞獅頭啦</h6>
                    <p>點擊開啟</p> <br></br>
                    </a>
                    </Col>
                    <Col className="text-center" xs={6} md={3}>
                    <a href="https://www.facebook.com/fbcameraeffects/tryit/5726820800756993/" target='blank'>
                    <img src={filter9} alt="Facebook AR Filter" className="about_M2gether_IMG" />
                    <h6>自拍系列：試試花旦造型</h6>
                    <p>點擊開啟</p> <br></br>
                    </a>
                    </Col>
                    <Col className="text-center" xs={6} md={3}>
                    <a href="https://www.facebook.com/fbcameraeffects/tryit/237109922269938/" target='blank'>
                    <img src={filter10} alt="Facebook AR Filter" className="about_M2gether_IMG" />
                    <h6>自拍系列：關帝亭浪漫櫻花</h6>
                    <p>點擊開啟</p> <br></br>
                    </a>
                    </Col>
                </Row>



        </Layout>
    );
}


export const Head = () => (
    <Seo
        title="長洲太平清醮 AR多重宇宙 AR互動遊戲/濾鏡連結(Facebook版)"
        description="从二創作團隊致力推廣香港傳統文化活動，並在長洲太平清醮注入新元素，透過混合創新科技與傳統文化元素，利用AR擴增實景互動遊戲／濾鏡的形式，讓公眾以互動有趣的遊戲，全新角度去接觸本地文化。"
        siteUrl="http://m2gether.com/bunfestival/fb-filter"
    />
)




